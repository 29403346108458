/* jshint esversion:6 */
import '../../../../../../system/library/password/password-policy.js';

/*
function showPassword(){
    var password = $('#password');
    var type = password.attr('type');
    if(type === 'text'){
        password.attr('type', 'password');
    }else{
        password.attr('type', 'text');
    }
}

 */




