import "jquery-ui/dist/jquery-ui.min.js";
import "jquery-ui/themes/base/base.css";
import "jquery-ui/themes/base/all.css";
import 'jquery-timepicker/jquery.timepicker.css';
import 'jquery-timepicker/jquery.timepicker.js';
import 'jquery-datetimepicker/build/jquery.datetimepicker.full.min.js';
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css';
import 'summernote/dist/summernote.js';
import 'summernote/dist/summernote.css';
import 'summernote/dist/summernote-lite.css';
import 'summernote/dist/summernote-lite.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
window.Swal = require('sweetalert2/dist/sweetalert2.min.js');
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'amcharts/dist/amcharts/amcharts.js';
import 'amcharts/dist/amcharts/pie.js';
import 'amcharts/dist/amcharts/serial.js';
import 'amcharts/dist/amcharts/plugins/export/export.js';
require('jquery-ui/ui/disable-selection');
import 'multiselect/js/jquery.multi-select.js';
import 'jquery.quicksearch/src/jquery.quicksearch.js';
import 'multiselect/css/multi-select.css';
import 'select2/dist/js/select2.min.js';
import 'select2/dist/css/select2.css';
window.Toastify = require('toastify-js/src/toastify');
import 'toastify-js/src/toastify.css';
import '@claviska/jquery-minicolors/jquery.minicolors.js';
import '@claviska/jquery-minicolors/jquery.minicolors.css';
//import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import 'datatables.net/js/jquery.dataTables.mjs';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import '../../js/flyco-multiple-select.js';