function initFlycoMultiSelect(element, activeAllBtns, searchActive, formElementName) {
    let opts = [];
    let optionsItems = element.find(".flyco-ms-item");
    for (let x = 0; x < optionsItems.length; x++) {
        let optionItem = optionsItems.eq(x);

        opts.push({
            value: optionItem.attr("value"),
            title: optionItem.html(),
            selected: optionItem.attr("selected") ? true : false,
            disabled: optionItem.attr("disabled") ? true : false,
            class: optionItem.attr("class"),
            dataType: optionItem.attr("data-type"),
            order: x,
        });
    }


    let notSelectOptions = '', selectedOptions = '', selectedHiddenOptions = '';
    for (let key in opts) {
        let item = opts[key];
        if (item?.selected) {
            selectedOptions += `<li data-sortby="${item.order}" value="${item?.value}" ${(item?.disabled ? 'disabled' : '')}>${item?.title}</li>`;
            selectedHiddenOptions += `<option value="${item?.value}" selected>${item?.title}</option>`;
        } else {
            notSelectOptions += `<li data-sortby="${item.order}" class="${item?.class}" value="${item?.value}" data-type="${item?.dataType}" ${(item?.disabled ? 'disabled' : '')}>${item?.title}</li>`;
        }
    }

    /* Select All ve Deselect All Buttons (Deafult değeri true olarak geliyor) */
    let selectAllBtn = activeAllBtns ? '<button type="button" id="select_all_button" class="flyco-ms-select-btn btn btn-sm btn-secondary">Select All</button>' : '';
    let selectDeselectAllBtn = activeAllBtns ? '<button type="button" id="deselect_all_button" class="flyco-ms-select-btn btn btn-sm btn-secondary">Deselect All</button>' : '';

    /* Search (Deafult değeri true olarak geliyor) */
    let selectableSearch = searchActive ? '<input type="text" placeholder="search" class="flyco-ms-search-input" id="select_search_input">' : '';
    let selectionSearch = searchActive ? '<input type="text" placeholder="search" class="flyco-ms-search-input" id="selected_search_input">' : '';

    element.html(`
            <div class="flyco-ms">
                <div class="flyco-ms-select-box">
                    ${selectableSearch}
                    <ul class="flyco-ms-ul flyco-ms-selectable-ul" id="flyco_ms_selectable" multiple>${notSelectOptions}</ul>
                    ${selectAllBtn}
                </div>
    
                <div class="flyco-ms-select-box">
                    ${selectionSearch}
                    <ul class="flyco-ms-ul flyco-ms-selection-ul" id="flyco_ms_selection" multiple>${selectedOptions}</ul>
                    ${selectDeselectAllBtn}
                </div>
    
                <div class="flyco-ms-select-box-hidden">
                    <select name="${formElementName}[]" id="${formElementName}" class="flyco-ms-result" multiple>${selectedHiddenOptions}</select>
                </div>
            </div>
        `);
}

$.fn.extend({
    flycoMultiSelect: function ({
                                    activeAllBtns = true,
                                    searchActive = true,
                                    onChange = () => null
                                }) {
        let element = this;
        let formElementName = element.attr("name");
        initFlycoMultiSelect(element, activeAllBtns, searchActive, formElementName);
        let left = element.find("#flyco_ms_selectable");
        let right = element.find('#flyco_ms_selection');
        let result = element.find('.flyco-ms-result');


        function initSelectedResult() {
            let options = right.find("li");
            let selectedOpts = [];
            let searchInput = $('#select_search_input');
            result.html('');
            for (let x = 0; x < options.length; x++) {
                let optionItem = options.eq(x);
                selectedOpts.push({
                    value: optionItem.attr("value"),
                    title: optionItem.html(),
                    selected: true,
                    order: x
                });
                result.append(`<option value="${optionItem.attr("value")}" selected>${optionItem.html()}</option>`);

            }
            onChange(selectedOpts);
        }

        function orderListItems() {
            //TO PROVIDE SUPPORT OF USING MULTIPLE MULTISELECT ON THE SAME PAGE.
            $('.flyco-ms-selectable-ul').each(function (index, element) {
                li = $(element).children();

                li.detach().sort(function (a, b) {
                    return $(a).data('sortby') - $(b).data('sortby');
                });

                $(element).append(li);
            });
        }

        left.on("click", "li", function () {
            right.append(this);
            initSelectedResult();
        });

        right.on("click", "li", function () {
            left.append(this);
            initSelectedResult();
            orderListItems();
        });


        /* Select All Search */
        element.find('#select_search_input').on("keyup", function () {
            let selectinputVal = $(this).val().toLowerCase();
            left.find('li').filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(selectinputVal) > -1);
            });
        });

        /* Selected All Search */
        element.find('#selected_search_input').on("keyup", function () {
            let selectedinputVal = $(this).val().toLowerCase();
            right.find('li').filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(selectedinputVal) > -1);
            });
        });

        /* Select All Button */
        element.find('#select_all_button').click(function () {
            right.append(left.find('li:not([style*="display: none"])'));
            initSelectedResult();
        });

        /* Deselect All Button */
        element.find('#deselect_all_button').click(function () {
            left.append(right.find('li:not([style*="display: none"])'));
            orderListItems();
            initSelectedResult();
        });

    }
});