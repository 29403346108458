/*jshint esversion: 6 */
import './login.scss';
function checkLogin(){

    let username = $('#username').val();

    username= username.trim();

    let companySSOControlText = checkSSOPolicy(username);

    if (companySSOControlText !== ''){
        window.alert(companySSOControlText);
        return false;
    }

    $('.login-form').submit();

    localStorage.clear();
}

function checkSSOPolicy(string) {

    let POLICY_REGEX_ARRAY = JSON.parse(ssoPolicyRegex.replace(/&quot;/g,'"'));
    let errorMessage ='';
    POLICY_REGEX_ARRAY.forEach(function (r) {
        if  (testRegex(string,r['regex_pattern_for_js'])){
            errorMessage += textSelectSSOCompany;
        }
    });
    return errorMessage;
}

function testRegex(string, regex) {

    const regexExp = new RegExp(regex);
    return regexExp.test(string);
}

function showPassword(){
    var password = $('#password');
    var type = password.attr('type');
    if(type === 'text'){
        password.attr('type', 'password');
        $('.password-show').hide();
        $('.password-hide').show();
    }else{
        password.attr('type', 'text');
        $('.password-hide').hide();
        $('.password-show').show();
    }
}


window.showPassword = showPassword;
window.checkSSOPolicy = checkSSOPolicy;
window.checkLogin = checkLogin;


